import React from 'react';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Topbar from './Components/Topbar';
import Dashboard from './Components/Dashboard';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import About_me from './Components/About me';

import './App.css';
import Collatz from './Components/Collatz';
import GuessingGame from './Components/Guessing game';

function App() {
  return (
    <Router>
      <div className="App">
        <div className="container">
        <div className="topbar"><Topbar /></div>
          <div className="main">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About_me />} />
              <Route path="/collatz" element={<Collatz/>} />
              <Route path="/guessinggame" element={<GuessingGame/>} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
