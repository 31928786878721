import React, { useState } from 'react';

const Collatz = () => {
    const [inputNumber, setInputNumber] = useState('');
    const [results, setResults] = useState([]);
    const calculateCollatz = () => {
        let currentNumber = parseInt(inputNumber, 10);
        const newResults = [];

        while (currentNumber !== 1) {
            if (currentNumber % 2 === 0) {
            currentNumber /= 2;
            } else {
            currentNumber = currentNumber * 3 + 1;
            }

            newResults.push(currentNumber);
        }

        setResults(newResults);
            };

    return (
    <div>
        <label>
        Enter a number:
        <input
            type="number"
            value={inputNumber}
            onChange={(e) => setInputNumber(e.target.value)}
        />
        </label>
        <button onClick={calculateCollatz}>Calculate</button>

        <div>
        {/* Display the results */}
        {results.map((result, index) => (
            <div key={index}>{result}</div>
        ))}
        </div>
    </div>
    );

    };
export default Collatz;