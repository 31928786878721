import React, { useState } from 'react';

function GuessingGame() {
  const [secretNumber, setSecretNumber] = useState(generateRandomNumber());
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');
  const [guessCount, setGuessCount] = useState(0);

  function generateRandomNumber() {
    return Math.floor(Math.random() * 100) + 1;
  }

  function handleInputChange(event) {
    setGuess(event.target.value);
  }

  function handleGuess() {
    const userGuess = parseInt(guess, 10);

    if (isNaN(userGuess)) {
      setMessage('Please enter a valid number.');
    } else {
      setGuessCount(guessCount + 1);

      if (userGuess === secretNumber) {
        setMessage(`Congratulations! You guessed the correct number in ${guessCount} guesses.`);
      } else {
        setMessage(
          userGuess > secretNumber
            ? 'Too high. Try again.'
            : 'Too low. Try again.'
        );
      }
    }
  }

  return (
    <div>
      <h1>Number Guessing Game</h1>
      <p>{message}</p>
      <p>Number of guesses: {guessCount}</p>
      <input
        type="text"
        value={guess}
        onChange={handleInputChange}
        placeholder="Enter your guess"
      />
      <button onClick={handleGuess}>Guess</button>
    </div>
  );
}

export default GuessingGame;
