import React from "react";
import {Link} from 'react-router-dom';

const topbar= () =>{
    return (
        <div class="topbar">
            <li>
                <Link to='/'>Home</Link>
            </li>
            <li>
                <Link to='/projects'>Projects</Link>
            </li>
            <li>
                <Link to='/about'>About Me</Link>
            </li>
            <li>
                <Link to='/contact'>Contact</Link>
            </li>
        </div>
    );
}

export default topbar;