import React from "react";

const About_me = () => {
        return(
            <div>
                I am a junior software developer located in Colorado Springs, CO. I began learning software development as a hobby after receiving my Bachelor of Science in Accounting, and finally decided I'd like to pursue this as a career. I completed the Nucamp React Bootcamp in 2022 and have been self teaching and coding in my spare time. When I'm not at work or writing code, I'm spending time with my wife and our infant son, exploring our colorful state of Colorado. 
            </div>
        )
}

export default About_me;