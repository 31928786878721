import React from "react";

const Dashboard = () => {
    
    return (
        <h1>Hi, I'm Ryan.
        I'm a React.js Developer.</h1>
    );
};

export default Dashboard;