import React from "react";

const Contact = () => {
    return(
        <div>
            <h1>
                My contact info
            </h1>
            <h4>
                <p><a href="mailto:ryan-tiffany@live.com">Email</a></p>
                <p><a href="https://www.linkedin.com/in/ryan-tiffany/" target="_blank">Linkedin</a></p>
            </h4>
            <form name="contact" className="DefaultForm" action="/contact" method="post">
                <input type="hidden" name="form-name" value="contact" />
                <input required type="text" id="user_name" name="user_name" placeholder= "Name"/>
                <input required type="email" id="email" name="email" placeholder= "Email" />
                <textarea id="message" name="message" placeholder= "Your message"/>
                <button type="submit">Submit</button>
            </form>

        </div>
    )

}

export default Contact;