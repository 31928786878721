import React from "react";
import {Link} from 'react-router-dom';

const Projects = () => {
    return (
        <div>
            <h3>
                Highlighted projects
            </h3>
            <h5>
                <Link to='https://ryantiffanysbugtracker.netlify.app/' target='blank'>Bug Tracker</Link>
            </h5>
            <h3>
                Other projects
            </h3>
            <h5>
                <p><Link to='/guessinggame'>Guessing Game</Link></p>
                <p><Link to='/collatz'>Collatz Conjecture</Link></p>
            </h5>
        </div>
    );
}

export default Projects;